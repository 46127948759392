import * as React from "react";
import { useCookies } from "react-cookie";
import { Link } from "gatsby";
import AdSense from "react-adsense";
import Navbar from "./navbar";

const Layout = ({ children }) => {
  const [cookies, setCookie] = useCookies(["name"]);
  const [hideCookieNotice, setHideCookieNotice] = React.useState(true);
  React.useEffect(() => {
    const acceptCookieNotice = cookies.acceptCookieNotice;
    setHideCookieNotice(acceptCookieNotice);
  }, [cookies.acceptCookieNotice]);
  const closeCookieNotice = () => {
    setCookie("acceptCookieNotice", true, { path: "/", sameSite: "strict" });
    setHideCookieNotice(true);
  };

  return (
    <div className={"bg-gray-50"}>
      <Navbar
        navItems={[
          { link: "/", text: "หน้าแรก" },
          { link: "/fund/list", text: "กองทุน" },
          { link: "/global/list", text: "บลจ. ต่างประเทศ" },
          { link: "/about", text: "เกี่ยวกับเรา" },
          { link: "/contact", text: "ติดต่อ" },
        ]}
      />
      <main>{children}</main>
      <div className="mt-10 max-w-screen-md mx-auto">
        <AdSense.Google
          client="ca-pub-2006387718675901"
          slot="7648031287"
          style={{ display: "block" }}
          format="auto"
          responsive="true"
        />
      </div>
      <hr className="mt-6 mb-5 border-gray-200" />
      <footer className="container max-w-screen-md mx-auto px-4 pb-16 sm:pb-14 text-gray-400 text-sm">
        <p className="mb-4 max-w-screen-sm text-center">
          คำเตือน: ผู้จัดทำเว็บไซต์ขอสงวนสิทธิ์ที่จะไม่รับผิดชอบต่อความถูกต้อง
          ครบถ้วน และความเสียหายต่างๆ ที่เกิดขึ้นทุกกรณีจากการนำข้อมูลไปใช้
          ผู้ลงทุนควรศึกษาข้อมูลจากบริษัทหลักทรัพย์จัดการกองทุน
          ก่อนตัดสินใจลงทุน
        </p>
        <p className="text-center">
          &copy; กองแม่.com
          <span className="ml-3">
            <Link to="/about">เกี่ยวกับเรา</Link>
          </span>
          <span className="ml-3">
            <Link to="/contact">ติดต่อ</Link>
          </span>
          <span className="ml-6">
            <a
              href="https://www.facebook.com/%E0%B8%81%E0%B8%AD%E0%B8%87%E0%B9%81%E0%B8%A1%E0%B9%88com-107040114854478"
              className="hover:text-blue-700"
            >
              <svg
                className="inline align-text-top h-5 w-5"
                aria-hidden="true"
                data-prefix="fab"
                data-icon="facebook-square"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M448 80v352c0 26.5-21.5 48-48 48h-85.3V302.8h60.6l8.7-67.6h-69.3V192c0-19.6 5.4-32.9 33.5-32.9H384V98.7c-6.2-.8-27.4-2.7-52.2-2.7-51.6 0-87 31.5-87 89.4v49.9H184v67.6h60.9V480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z"
                ></path>
              </svg>
            </a>
          </span>
        </p>
      </footer>
      <div
        className={`${
          hideCookieNotice ? "hidden " : ""
        }alert-footer w-full fixed bottom-0 cookie-banner`}
      >
        <div className="leading-relaxed text-xs text-center w-full p-2 bg-gray-200 text-gray-500">
          เว็บไซต์นี้ใช้คุกกี้ เพื่อการแสดงผล พัฒนา และ ปรับปรุงเว็บไซต์
          <button
            className="ml-3 px-2 py-1 border border-transparent rounded-md shadow-sm text-blue-800 border-blue-500 bg-blue-100 hover:bg-blue-200"
            onClick={closeCookieNotice}
          >
            ตกลง
          </button>
        </div>
      </div>
    </div>
  );
};

export default Layout;
